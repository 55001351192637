let InterimList = [
  {
    name: "上海艾融软件股份有限公司2024年半年报告.pdf",
    to: "https://www.bse.cn/disclosure/2024/2024-08-23/c0f28e9f65a3423c97d87b3d3de65552.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2024年半年报告摘要.pdf",
    to: "https://www.bse.cn/disclosure/2024/2024-08-23/89e0d1273c0a49f88d455fe428dd3b97.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2023年半年报告.pdf",
    to: "https://www.bse.cn/disclosure/2023/2023-08-25/7db56ed3b08f45b49ac35feb1589e3fa.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2023年半年报摘要.pdf",
    to: "https://www.bse.cn/disclosure/2023/2023-08-25/6bb6f0a2d2e54ef6b92d8102add9a3b7.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2022年半年报告.pdf",
    to: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857326_585816.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2021年半年报告.pdf",
    to: "http://www.bse.cn/disclosure/2021/2021-08-30/1630318989_804423.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2020年半年报告.pdf",
    to: "http://www.bse.cn/disclosure/2020/2020-08-25/1598345406_327972.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2019年半年报告.pdf",
    to: "http://www.bse.cn/disclosure/2021/2021-08-30/1630318989_804423.pdf",
    type: "pdf",
  },
];

export default {
  "get|/parameter/InterimList": () => {
    return {
      status: 200,
      message: "success",
      data: InterimList,
    };
  },
};
