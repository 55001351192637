const Mock = require('mockjs');

//设置ajax请求的相应的时间
Mock.setup({
    timeout:'200-600'
});

let configArray = [];

const files = require.context('.', true ,/\.js$/);
//使用webpack读取所有的mock文件
files.keys().forEach((key)=> {
    if( key==='./index.js') return;
    configArray = configArray.concat(files(key).default);
})

//注册所有的mock服务
configArray.forEach((item)=>{
    for (let [path, target] of Object.entries(item)) {
        let protocol = path.split('|')
        Mock.mock(new RegExp('^'+protocol[1]), protocol[0], target);
    }
})
