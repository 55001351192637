/*
 * @Author: your name
 * @Date: 2021-10-12 16:17:38
 * @LastEditTime: 2021-11-01 10:34:13
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /official/src/store/getter.js
 */
const getters = {
    videoState: state => state.account.videoState,
    afterScroll: state => state.account.afterScroll
}

export default getters