/*
 * @Author: your name
 * @Date: 2021-10-15 15:34:23
 * @LastEditTime: 2021-11-01 10:54:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official/src/store/modules/account.js
 */
const account = {
    state: {
        videoState: false,
        afterScroll: false,
        
    },
    mutations: {
        TO_VIDEOS: (state) => {
            state.videoState = true
        },
        TO_VIDEOCLOSE:(state) => {
            state.videoState = false
        },
        TO_AfterScroll:(state) => {
            state.afterScroll = true
        },
        TO_AfterScroll_false:(state) => {
            state.afterScroll = false
        },
    }
}
export default account