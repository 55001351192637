import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import getters from './getter'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
    },
    mutations: {

    },
    modules: {
        account
    },
    getters
})

export default store