let QuarterlyList = [
  {
    name: "上海艾融软件股份有限公司2024年第三季度报告.pdf",
    to: "https://www.bse.cn/disclosure/2024/2024-10-30/431bf045efef415aba8a6dd33ade0130.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2024年第一季度报告.pdf",
    to: "https://www.bse.cn/disclosure/2024/2024-04-26/0f661de6d31141c4b668e3c535678b57.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2023年第三季度报告.pdf",
    to: "https://www.bse.cn/disclosure/2023/2023-10-27/3be4d22f0f2d4c6cb9dffcef50101891.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2023年第一季度报告.pdf",
    to: "https://www.bse.cn/disclosure/2023/2023-04-26/0e87caffc8fc417e93b603b979538a3e.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2022年第三季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2022/2022-10-27/1666868698_208663.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2022年第一季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2022/2022-04-29/1651222913_422539.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2021年第三季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2021/2021-10-29/1635493234_928080.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2021年第一季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2021/2021-04-29/1619690973_878427.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2020年第三季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2020/2020-10-29/1603964781_876188.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2020年第一季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2020/2020-04-30/1588246765_733233.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2019年第三季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2019/2019-10-31/1572508094_763249.pdf",
    type: "pdf",
  },
  {
    name: "上海艾融软件股份有限公司2019年第一季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2019/2019-04-29/1556527004_842375.pdf",
    type: "pdf",
  },
];
export default {
  'get|/parameter/QuarterlyList': () => {
    return {
      status: 200,
      message: 'success',
      data: QuarterlyList
    };
  }
}
