/*
 * @Author: your name
 * @Date: 2021-10-12 15:43:36
 * @LastEditTime: 2024-08-19 16:45:31
 * @LastEditors: lyt
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /official_web_portal/official/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home.vue'),
        redirect: '/first',
        children: [
            {
                path: '/first',
                name: 'First',
                component: () => import('../views/home/index.vue')
            },
            {
                path: '/introduce',
                name: 'Introduce',
                component: () => import('../views/introduce/index.vue')
            },
            {
                path: '/solve',
                name: 'Solve',
                component: () => import('../views/solveMethods/index.vue')
            },
            // 公司动态
            {
                path: '/dynamic',
                name: 'Dynamic',
                component: () => import('../views/dynamic/index.vue')
            },
            // 首页
            {
                path: '/relations',
                name: 'Relations',
                component: () => import('../views/relations/index.vue')
            },
            // 业绩报告
            {
                path: '/Achievement',
                name: 'Achievement',
                component: () => import('../views/relations/Achievement.vue')
            },
            // 公司公告
            {
                path: '/Announce',
                name: 'Announce',
                component: () => import('../views/relations/Announce.vue')
            },
            // 公司治理
            {
                path: '/government',
                name: 'government',
                component: () => import('../views/relations/government.vue')
            },
            // 投资者服务
            {
                path: '/Service',
                name: 'Service',
                component: () => import('../views/relations/Service.vue')
            },
            {
                path: '/joinus',
                name: 'joinus',
                component: () => import('../views/joinUs/index.vue'),
            },
            {
                path: '/recruit',
                name: 'recruit',
                component: () => import('../views/joinUs/recruit.vue')

            },
            // 招聘考试
            {
                path: '/examList',
                name: '/examList',
                component: () => import('../views/joinUs/examList.vue')

            },

        ]
    },
    {
        // 离职证明
        path: '/lzzm',
        name: '/lzzm',
        component: () => import('../views/dimissionCertify/index.vue')
    }

]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
