let annualList = [
    {
        name: "上海艾融软件股份有限公司2023年年度报告.pdf",
        to: "https://www.bse.cn/disclosure/2024/2024-04-19/ca69cc5937b241a382e27a9c00f56e15.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2023年年度报告摘要.pdf",
        to: "https://www.bse.cn/disclosure/2024/2024-04-19/1ca7bd05d74c4f69ac667536c88287a6.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2022年年度报告.pdf",
        to: "https://www.bse.cn/disclosure/2023/2023-04-26/4977c9e9351545fc9547c4def1c3cf9b.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2022年年度报告摘要.pdf",
        to: "https://www.bse.cn/disclosure/2023/2023-04-26/b7d3e6ab000943a5b0a026d476c82141.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2021年年度报告.pdf",
        to: "http://www.bse.cn/disclosure/2022/2022-04-22/1650629913_953552.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2020年年度报告.pdf",
        to: "http://www.bse.cn/disclosure/2021/2021-03-31/1617189499_510303.pdf",
        type: "pdf"
    },
    {
        name: "上海艾融软件股份有限公司2019年年度报告.pdf",
        to: "http://www.bse.cn/disclosure/2020/2020-03-23/1584959987_109503.pdf",
        type: "pdf"
    }
];

export default {
    'get|/parameter/annualList': () => {
        return {
            status: 200,
            message: 'success',
            data: annualList
        };
    }
}
