let dynamicList = [
  {
    content: "艾融软件子公司入选上海市高成长企业",
    url: "https://hea.china.com/article/20231206/122023_1451525.html",
  },
  {
    content: "艾融软件荣获第二十五届金牛奖——金牛小巨人奖",
    url: "http://finance.jrj.com.cn.comxs.net/a/news/21090.html",
  },
  {
    content: "艾融软件被授予“上海市崇明区商业秘密保护示范点”",
    url: "https://hea.china.com/article/20231124/112023_1444657.html",
  },
  {
    content: "艾融软件基于大模型和数字人技术解决方案 荣获2023沪港台三地上银金融科技大赛最具潜力奖",
    url: "https://www.cet.com.cn/wzsy/cyzx/3482142.shtml",
  },
  {
    content: "艾融软件金融信创业务快速发展 积极把握信创市场机会 ",
    url: "https://finance.stockstar.com/IG2023090100009961.shtml ",
  },
  {
    content: "艾融软件半年报发布 积极布局人工智能未来业绩可期",
    url: "https://caifuhao.eastmoney.com/news/20230830115226050930570",
  },
  {
    content: "艾融软件半年报发布 净利润较去年同期增长53%",
    url: "https://www.cet.com.cn/wzsy/cyzx/3434321.shtml",
  },

  {
    content: "艾融软件上半年净利润增长53%：业绩加速跑 AI业务取得突破",
    url: "http://www.wabei.cn/Home/News/292150",
  },
  {
    content: "推动人工智能等技术在金融业务中的应用 艾融软件2023上半年盈利2357.96万元",
    url: "https://www.p5w.net/kuaixun/202308/t20230825_5722401.htm",
  },
  {
    content: "艾融软件披露2023年一季报 净利润较去年同期大涨55%",
    url: " https://finance.stockstar.com/IG2023042600029662.shtml",
  },
  {
    content: "艾融软件加入openGauss社区，“信创”又添新利器",
    url: "https://www.cet.com.cn/itpd/itxw/3335533.shtml",
  },
  {
    content: "艾融软件：金融数字化运营正当时",
    url: "https://finance.stockstar.com/IG2023030200005208.shtml",
  },
  {
    content: "艾融软件：克服疫情困难 业绩逆势飘红",
    url: "https://caifuhao.eastmoney.com/news/20230227204056474135010",
  },
  {
    content: "未来已来，“北交所金融科技第一股”艾融软件前景可期",
    url: "https://caifuhao.eastmoney.com/news/20230222155829923825450",
  },
  {
    content: "艾融软件宣布通过百度智能云接入文心一言能力 打造艾融金融人工智能全系产品/服务",
    url: "https://www.cet.com.cn/itpd/itxw/3326523.shtml",
  },
  {
    content: "艾融软件宣布通过百度智能云接入文心一言能力 打造艾融金融人工智能全系产品/服务",
    url: "https://caifuhao.eastmoney.com/news/20230220120656127321140",
  },
  {
    content: "艾融软件宣布通过百度智能云接入文心一言能力 打造艾融金融人工智能全系产品/服务",
    url: "https://finance.stockstar.com/IG2023022000002656.shtml",
  },
  {
    content: "未来已来:艾融软件持续加码数字经济相关产品研发创新",
    url: " https://caifuhao.eastmoney.com/news/20230131083847561066540",
  },
  {
    content: "艾融软件获2021年度金牛北交所公司奖",
    url: "https://caifuhao.eastmoney.com/news/20221230160458320280310",
  },
  {
    content: "金融数字化转型，艾融软件推出深度解决方案",
    url: "https://caifuhao.eastmoney.com/news/20221202174406828914500",
  },
  {
    content: "艾融软件深度参与供销社数字化转型",
    url: "https://caijing.chinadaily.com.cn/a/202212/01/WS63883381a3102ada8b224c0e.html",
  },
  {
    //  num: "47",
    content: "加速扩容 公募持仓提升！企业：北交所的影响是巨大的｜北交所一周年",
    url: "http://www.p5w.net/kuaixun/202211/t20221115_5595333.htm",
  },
  {
    //  num: "46",
    content: "艾融软件：用专注专业态度提供定制服务",
    url: "https://paper.cnstock.com/html/2022-11/15/content_1692664.htm",
  },
  {
    //  num: "45",
    content: "艾融软件入选北证50，金融信创业务步入“快车道”",
    url: "https://tech.china.com/article/20221110/112022_1175200.html",
  },
  {
    //  num: "44",
    content: "北证50+信创概念 艾融软件抢筹行情开启",
    url: "https://finance.eastmoney.com/a/202211092555485135.html",
  },
  {
    //  num: "01",
    content:
      "上海市经济和信息化委员会提名我单位作为第一完成单位申报2022年度上海市科技进步奖的情况公示",
    url: 'National S&T Progress Award.jpeg'
  },
  {
    //  num: "02",
    content:
      "艾融软件中标某行普惠业务需求项目 开启银行业务需求引领技术开发新模式",
    url: "https://finance.eastmoney.com/a/202207122446932358.html",
  },
  {
    //  num: "03",
    content: "艾融软件全资子公司中标银联商务项目",
    url: "https://news.cnstock.com/news,bwkx-202207-4916837.htm",
  },
  {
    //  num: "04",
    content: "携手北理工 艾融软件加入元宇宙人才培养大潮",
    url: "https://finance.eastmoney.com/a/202207042433851977.html",
  },
  {
    //  num: "05",
    content: "艾融软件推出“眼神支付”产品,落地元宇宙数字人民币支付场景应用",
    url: "https://finance.eastmoney.com/a/202206272425917137.html ",
  },
  {
    //  num: "06",
    content: "艾融软件普惠小微深度数字化立体解决方案 助力银行支持实体经济",
    url: "http://www.cinic.org.cn/zgzz/qy/1309856.html ",
  },
  {
    //  num: "07",
    content: "艾融软件突进汽车“智能座舱“软件业务 \n",
    url: "https://finance.eastmoney.com/a/202206202418281959.html",
  },
  {
    //  num: "08",
    content: "深耕金融数字化转型，艾融软件旗下宜签网络与上海农商行再度携手！",
    url: "https://finance.eastmoney.com/a/202206152413849439.html ",
  },
  {
    //  num: "09",
    content: "艾融软件董事长张岩喜获聘为元宇宙专业委员会委员",
    url: "https://tech.china.com/article/20220614/062022_1084881.html",
  },
  {
    //  num: "10",
    content: "艾融软件强力推进复工复产 订单产能双双呈现强劲恢复态势",
    url: "http://www.ceh.com.cn/syzx/1494292.shtml ",
  },
  {
    //  num: "11",
    content: "复工复产，艾融软件添助力—数字化风控秀出“硬核实力”",
    url: "https://www.cet.com.cn/itpd/itxw/3189497.shtml ",
  },
  {
    //  num: "12",
    content: "艾融软件金融数字员工解决方案受到金融行业关注 ",
    url: "https://finance.eastmoney.com/a/202206062402432224.html ",
  },
  {
    //  num: "13",
    content: "艾融软件智能OCR产品开拓金融数字化新领域 ",
    url: "http://news.ifeng.com/c/8GSV7IeQ2xv",
  },
  {
    //  num: "14",
    content: "艾融软件深耕银行数字化转型市场，业务中台产品又一案例即将上线",
    url: "https://finance.eastmoney.com/a/202205302396256147.html",
  },
  {
    //  num: "15",
    content: "艾融软件落地金融数字化经营解决方案",
    url: "http://www.cb.com.cn/index/show/gd/cv/cv1361616991499",
  },
  {
    //  num: "16",
    content: "艾融软件持续助力推进银行金融信息系统自主可控",
    url: "https://finance.eastmoney.com/a/202205232388130720.html",
  },
  {
    //  num: "17",
    content: "艾融软件：公司已进军汽车数字化行业领域",
    url: "https://www.p5w.net/kuaixun/202205/t20220518_5550036.htm",
  },
  {
    //  num: "18",
    content: "艾融软件：公司2021年接连中标多家银行客户项目，目前在手订单充足",
    url: "https://www.p5w.net/kuaixun/202205/t20220518_5550037.htm",
  },
  {
    //  num: "19",
    content:
      "艾融软件：汽车软件行业新客户2021年已实现营收 有望成为业务及利润新增长点",
    url: "https://www.p5w.net/kuaixun/202205/t20220518_5550132.htm",
  },
  {
    //  num: "20",
    content: "艾融软件发力金融数字化转型深水区",
    url: " https://finance.eastmoney.com/a2/202205182383549397.html",
  },
  {
    //  num: "21",
    content: "艾融软件数字人民币应用场景建设步入快车道",
    url: " https://finance.eastmoney.com/a/202205172381961525.html",
  },
  {
    //  num: "22",
    content: "艾融软件推出全新活体风控引擎，有效优化人脸身份认证管理",
    url: "http://biz.ifeng.com/c/8G3dRjY27iR",
  },
  {
    //  num: "23",
    content: "艾融软件系列产品全面兼容鸿蒙，助力软件国产化",
    url: "https://js.news.163.com/22/0511/12/H7394LD104249CU4.html",
  },
  {
    //  num: "24",
    content: "2022年艾融软件稳步推进汽车数字化行业业务",
    url: "http://www.cinic.org.cn/zgzz/qy/1287398.html",
  },
  {
    //  num: "25",
    content: "艾融软件金融信创业务实现超300%快速增长",
    url: "https://tech.china.com/article/20220507/052022_1062783.html",
  },
  {
    //  num: "26",
    content: "发明专利达到十四项 艾融软件技术创新再添硕果",
    url: "https://www.sohu.com/na/544346309_121046563",
  },
  {
    //  num: "27",
    content:
      "艾融软件一季度营收同比增长59%：连续9个季度实现增长 持续强化研发力度",
    url: "http://www.wabei.cn/Home/News/223691",
  },
  {
    //  num: "28",
    content: "艾融软件连续9年通过ISO9001质量管理体系认证",
    url:
      "http://www.dzwww.com/xinwen/jishixinwen/202205/t20220505_10197917.htm",
  },
  {
    //  num: "29",
    content: "危难时刻，艾融软件勇当守“沪”者_",
    url: "https://tech.china.com/article/20220429/042022_1059315.html",
  },
  {
    //  num: "30",
    content: "艾融软件元宇宙金融领域应用研发取得亮眼成果",
    url: "https://bank.stockstar.com/IG2022042900004920.shtml",
  },
  {
    //  num: "31",
    content: "艾融软件成功入选首批特色化示范性软件学院合作企业 ",
    url: "https://www.cet.com.cn/itpd/itxw/3170190.shtml",
  },
  {
    //  num: "32",
    content: "艾融软件当选中国仿真学会元宇宙专业委员会成员单位",
    url: "http://biz.ifeng.com/c/8FbCgK3z87r",
  },
  {
    //  num: "33",
    content: "艾融软件，助力身份证电子化金融应用",
    url: "https://www.cet.com.cn/itpd/itxw/3169757.shtml",
  },

  {
    content: "艾融软件元宇宙金融领域应用研发取得亮眼成果",
    url: "https://biz.ifeng.com/c/8FY7fgJqPeK",
    //  num: "34",
  },
  {
    content: "艾融软件积极扩张战略见成效：2021年实现“加速跑”",
    url: "http://www.wabei.cn/Home/News/221844",
    //  num: "35",
  },
  {
    content: "2021艾融软件可视化年报",
    url: "https://fr.p5w.net/report/324259",
    //  num: "36",
  },
  {
    content: "九卦 | 张岩：危机是企业进化的关键时刻",
    url: "https://mp.weixin.qq.com/s/mc2CDg8pFyTz0XlV9uc7gA",
    //  num: "37",
  },
  {
    content: "艾融软件大规模升级远程软件开发服务，助力银行疫情期间保运转 ",
    url: "http://biz.ifeng.com/c/8FPlG88hopk",
    //  num: "38",
  },
  {
    content: "艾融董事长张岩成为北交所第一届行业咨询委员会委员",
    url: "https://www.cet.com.cn/wzsy/kjzx/3162883.shtml",
    //  num: "39",
  },
  {
    content: "[年报]艾融软件2021年净利润5038.03万元 现金分红比例近70%",
    url: "https://www.p5w.net/kuaixun/202204/t20220422_5542028.htm",
    //  num: "40",
  },
  {
    content: "艾融董事长张岩成为北交所第一届行业咨询委员会委员",
    url: "https://mp.weixin.qq.com/s/HZ3jm7_uWvhc6P0ZDc9YBg",
    //  num: "41",
  },
  {
    content: "艾融软件：闪送交付商业银行APP战疫专属服务",
    url: "https://yq.stcn.com/yqsl/202204/t20220421_4396844.html",
    //  num: "42",
  },
  {
    content: "艾融软件数字人民币业务已在多家商业银行实现落地",
    url: "http://www.cb.com.cn/index/show/gd/cv/cv1361600501490",
    //  num: "43",
  },
];


// 遍历dynamicList数组，替换num值（公司动态：num越小，排在最前）
for (let i = 0; i < dynamicList.length; i++) {
  dynamicList[i].num = i + 1;
  if (i < 9) {
    dynamicList[i].num = '0' + (i + 1);
  }
}

export default {
  "get|/parameter/dynamicList": () => {
    return {
      status: 200,
      message: "success",
      data: dynamicList,
    };
  },
};
