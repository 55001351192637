// 获取 mock.Random 对象
// const Mock = require('mockjs')

let annonceList = [
  {
    annoce_Num: "2024-103",
    content: "[临时公告]艾融软件:关于召开2024年第三次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2024/2024-10-30/e4fc3be4f81f4cf6b8d278e7c5d64cc3.pdf",
    date: "2024-10-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-102",
    content: "[临时公告]艾融软件:2024年第三季度权益分派预案公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-30/573311b9289d4897878bc5f2374cf7f8.pdf",
    date: "2024-10-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-101",
    content: "[临时公告]艾融软件:第四届监事会第十二次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-30/566fe17801d74fdfadc450b20c37909c.pdf",
    date: "2024-10-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-100",
    content: "[临时公告]艾融软件:第四届董事会第十三次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-30/05feb66e205641cc8fbabef37b3e2d70.pdf",
    date: "2024-10-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-099",
    content: "[定期报告]艾融软件:2024年三季度报告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-30/431bf045efef415aba8a6dd33ade0130.pdf",
    date: "2024-10-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-098",
    content: "[临时公告]艾融软件:关于全资子公司完成工商变更登记并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-23/cb73e91d5694465aadc17a6eeab04ef3.pdf",
    date: "2024-10-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-097",
    content: "[临时公告]艾融软件:股票交易异常波动及风险提示性公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-21/0381ae6ac8b744b68333b14d60dd7345.pdf",
    date: "2024-10-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-096",
    content: "[临时公告]艾融软件:关于控股子公司完成工商变更登记并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-18/292fbc5683d94e10b844ad9cbc83aca4.pdf",
    date: "2024-10-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-095",
    content: "[临时公告]艾融软件:股票交易异常波动及风险提示性公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-17/62108c4523e74775ae4edeec6346b000.pdf",
    date: "2024-10-17",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-094",
    content: "[临时公告]艾融软件:股票交易异常波动公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-14/1202549033d347ec8dc4e80f2b5205db.pdf",
    date: "2024-10-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-093",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-10-14/7159ef770a564ae49d20ccc2cc5c0567.pdf",
    date: "2024-10-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-092",
    content: "[临时公告]艾融软件:关于控股子公司完成工商变更登记并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-09/cac68aa075b54644a57d506760725819.pdf",
    date: "2024-10-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-091",
    content: "[临时公告]艾融软件:持股5%以上股东持股变动的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-09/1151857ace61415591612f455efd85f4.pdf",
    date: "2024-10-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-090",
    content: "[临时公告]艾融软件:股票交易异常波动公告",
    url: "https://www.bse.cn/disclosure/2024/2024-10-08/c3d928ab5edb4e449fcccdfb1d2afc1c.pdf",
    date: "2024-10-08",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-089",
    content: "[临时公告]艾融软件:简式权益变动报告书",
    url: "https://www.bse.cn/disclosure/2024/2024-09-23/c5d0d13b4dc242a3b80b25211d8023a9.pdf",
    date: "2024-09-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-088",
    content: "[临时公告]艾融软件:2024年半年度权益分派实施公告",
    url: "https://www.bse.cn/disclosure/2024/2024-09-13/c0df15116a0e447b8619e2f949809c48.pdf",
    date: "2024-09-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-087",
    content: "[临时公告]艾融软件:持股5%以上股东持股变动的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-09-12/46b824702762465a99c255369d5f334b.pdf",
    date: "2024-09-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-086",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2024年第二次临时股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2024/2024-09-12/6bf35bee949b4398b2fa73107f3fe190.pdf",
    date: "2024-09-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-085",
    content: "[临时公告]艾融软件:2024年第二次临时股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-09-12/8349327ef884435789b52689c2082679.pdf",
    date: "2024-09-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-084",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-09-03/048343e367e5431b87b7c091aafd5520.pdf",
    date: "2024-09-03",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-083",
    content: "[临时公告]艾融软件:回购股份注销完成暨股份变动公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-30/20a3a267868649838beadd6538b020ea.pdf",
    date: "2024-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-082",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-08-28/67ee3f56fe6343ad823f831adcada79b.pdf",
    date: "2024-08-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-081",
    content: "[临时公告]艾融软件:库存股注销完成暨股份变动公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/85812d2249b24a9f93c7744e1946f873.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-080",
    content: "[定期报告]艾融软件:2024年半年度报告摘要",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/89e0d1273c0a49f88d455fe428dd3b97.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-079",
    content: "[定期报告]艾融软件:2024年半年度报告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/c0f28e9f65a3423c97d87b3d3de65552.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-078",
    content: "[临时公告]艾融软件:关于召开2024年第二次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/a116905f9a3c4278989b00dc7e52404f.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-077",
    content: "[临时公告]艾融软件:2024年半年度权益分派预案公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/ef4be808acb140ca859a41844c7cde32.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-076",
    content: "[临时公告]艾融软件:第四届监事会第十一次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/0e01debf88754315bbc983227270e975.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-075",
    content: "[临时公告]艾融软件:第四届董事会第十二次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-23/e8461cf12cbd47e9894aee28d43e8bf5.pdf",
    date: "2024-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-074",
    content: "[临时公告]艾融软件:关于全资子公司完成工商变更登记并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-20/68be10ac892b467b99ec0a6bc358f03f.pdf",
    date: "2024-08-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-073",
    content: "[临时公告]艾融软件:持股5%以上股东拟通过大宗交易方式减持股份计划公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-15/fef58cc773ce48088fa1e37774f5faa0.pdf",
    date: "2024-08-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-072",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2024年第一次临时股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2024/2024-08-12/2d5caa7969d84cc2bb58367876669833.pdf",
    date: "2024-08-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-071",
    content: "[临时公告]艾融软件:关于减少注册资本通知债权人的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-12/f2281fadf8e64a9ab3161e9d31644320.pdf",
    date: "2024-08-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-070",
    content: "[临时公告]艾融软件:2024年第一次临时股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-12/804ac341818e4f25aff376f986e04ae8.pdf",
    date: "2024-08-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-069",
    content: "[临时公告]艾融软件:股权激励计划限制性股票解除限售公告",
    url: "https://www.bse.cn/disclosure/2024/2024-08-08/1a8198aa6c9a408787b7a9ca7297cd15.pdf",
    date: "2024-08-08",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-068",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-07-25/a84eff74de70478990fca805afd16824.pdf",
    date: "2024-07-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-067",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/84f12d7e80474eb8993e2fea636faaa2.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-066",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2021年股权激励计划第三个解除限售条件成就及回购注销部分限制性股票相关事宜之法律意见书",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/c0792036063248159154c7f9957c0227.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-065",
    content: "[临时公告]艾融软件:关于召开2024年第一次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/49477368ddf5416eadf38357cd00c24e.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-064",
    content: "[临时公告]艾融软件:关于注销部分已回购股份的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/875b3eb2a9e1461db3b4af57eefcab10.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-063",
    content: "[临时公告]艾融软件:关于回购注销部分股权激励计划限制性股票方案的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/23dc3e4568cd41a29758a399181c95b9.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-062",
    content: "[临时公告]艾融软件:关于公司股权激励计划第三个解除限售期解限售条件成就的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/f5500c85cbb5489286f719685200482b.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-061",
    content: "[临时公告]艾融软件:监事会关于第四届监事会第十次会议相关事项的审核意见",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/b48fa32aeaca438b94824d2f370bc7ce.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-060",
    content: "[临时公告]艾融软件:第四届监事会第十次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/83cace8ce97e49709fc41450c8ae6de2.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-059",
    content: "[临时公告]艾融软件:第四届董事会第十一次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-22/15f9f02998f24fd793b8ce302666857c.pdf",
    date: "2024-07-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-058",
    content: "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-07-18/7646cfcc256e497f8b3a2a598d1be15f.pdf",
    date: "2024-07-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-057",
    content: "[临时公告]艾融软件:购买资产的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-06-28/48259662940043c89a92119f29eee123.pdf",
    date: "2024-06-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-056",
    content: "[临时公告]艾融软件:第四届监事会第九次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-06-28/9ad62b6732234c7097bed1d88d5a48aa.pdf",
    date: "2024-06-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-055",
    content: "[临时公告]艾融软件:第四届董事会第十次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-06-28/be72921bf547473e94fd1897eeaefd23.pdf",
    date: "2024-06-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-054",
    content: "[临时公告]艾融软件:2023年年度权益分派实施公告",
    url: "https://www.bse.cn/disclosure/2024/2024-05-29/5063cba13b6841199670d723fe8a065a.pdf",
    date: "2024-05-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-053",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2023年年度股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2024/2024-05-22/2d78eca21a3742fc8f3ac72a90dcb4bd.pdf",
    date: "2024-05-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-052",
    content: "[临时公告]艾融软件:2023年年度股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-05-22/cf9d323865dc422791991926d13386ad.pdf",
    date: "2024-05-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-051",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-05-21/41357fa35ba24b979dc7748ec055c372.pdf",
    date: "2024-05-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-050",
    content: "[临时公告]艾融软件:2023年年度报告业绩说明会预告公告",
    url: "https://www.bse.cn/disclosure/2024/2024-05-13/07cadda2784246118da70aca33d12268.pdf",
    date: "2024-05-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-049",
    content: "[临时公告]艾融软件:回购进展暨回购股份结果公告",
    url: "https://www.bse.cn/disclosure/2024/2024-05-08/6d63e93b9a1d46dfa5932722fb176796.pdf",
    date: "2024-05-08",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-048",
    content: "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司向不特定合格投资者公开发行股票保荐工作总结报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-30/c2f07d0ed3834f79adfe33822fb58140.pdf",
    date: "2024-04-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-047",
    content: "[临时公告]艾融软件:关于对控股子公司减资的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-26/39832112d48c49259578b5d4e687c885.pdf",
    date: "2024-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-046",
    content: "[定期报告]艾融软件:2024年一季度报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-26/0f661de6d31141c4b668e3c535678b57.pdf",
    date: "2024-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-045",
    content: "[临时公告]艾融软件:第四届监事会第八次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-26/7749f37495cf44168637afedbe57f009.pdf",
    date: "2024-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-044",
    content: "[临时公告]艾融软件:第四届董事会第九次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-26/3302ddf8e7a744fb988bc9a5801c1620.pdf",
    date: "2024-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-043",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-04-23/2c2a7fddc1a44f51b73653e6a85355be.pdf",
    date: "2024-04-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-042",
    content: "[定期报告]艾融软件:2023年年度报告摘要",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/1ca7bd05d74c4f69ac667536c88287a6.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-041",
    content: "[定期报告]艾融软件:2023年年度报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/ca69cc5937b241a382e27a9c00f56e15.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-040",
    content: "[临时公告]艾融软件:关于召开2023年年度股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/8d47fe7e5ddd471e8f046bfe63903853.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-039",
    content: "[临时公告]艾融软件:关于提请股东大会授权董事会以简易程序向特定对象发行股票的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/03130eba50214e4d838b970c44c1e43b.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-038",
    content: "[临时公告]艾融软件:非经营性资金占用及其他关联资金往来情况的专项审计说明",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/f14779ca5158491c9905ed1a152c95bb.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-037",
    content: "[临时公告]艾融软件:关于预计2024年度向银行申请综合授信额度的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/7b781fa628d14aa496df4162ede29b51.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-036",
    content: "[临时公告]艾融软件:2023年年度权益分派预案公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/d6ef9ab73da046e89c76a1191d072ae0.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-035",
    content: "[临时公告]艾融软件:董事会对会计师事务所履职情况评估报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/b6e2f532f739453cb56b700b993f57eb.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-034",
    content: "[临时公告]艾融软件:董事会审计委员会对会计师事务所履行监督职责情况的报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/337fd6effba14b2688d17396c32a58e4.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-033",
    content: "[临时公告]艾融软件:拟续聘2024年度会计师事务所公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/6677b2fa67b04842a8e0064c47e6f0c9.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-032",
    content: "[临时公告]艾融软件:2023年度审计报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/c3f451e91d85451e9256f7dfc1f534b5.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-031",
    content: "[临时公告]艾融软件:2023年年度内部控制评价报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/2533c3204fec4d02b712f358f8f6bafd.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-030",
    content: "[临时公告]艾融软件:2023 年度董事会审计委员会履职情况报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/519465cb56af49c990c7c01592c82b35.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-029",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-雷富阳（已离任）",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/4245c24478b241a08bf8b7f4d91cda7e.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-028",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-陈明（已离任）",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/4e1dc2278f05448eb455a8b72878d68c.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-027",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-邓路（已离任）",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/567f0932ecc74b4faad1f8f3342024bd.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-026",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-朱以林",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/2a910a025a4e45faaf11f1db8d994b7d.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-025",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-谭毓安",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/9a9693c3f5ae4250872db3da4b17b4d8.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-024",
    content: "[临时公告]艾融软件:2023年度独立董事述职报告-周宁",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/fc2f8030486f4cb69ae60c94baf21442.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-023",
    content: "[临时公告]艾融软件:董事会关于独立董事独立性自查情况的专项报告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/2f64623aaf0348f6aaaffea39988b822.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-022",
    content: "[临时公告]艾融软件:第四届监事会第七次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/314c41d524124288a8f4cc318076e9df.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-021",
    content: "[临时公告]艾融软件:第四届董事会第八次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-19/53b1fd02d22045a989c27d97ed5a1dfc.pdf",
    date: "2024-04-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-020",
    content: "[临时公告]艾融软件:关于公司获得华为HarmonyOS 开发服务商资质认证的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-18/d854cb3f6b284a6bbd175b553bc26993.pdf",
    date: "2024-04-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-019",
    content: "[临时公告]艾融软件:关于公司获得华为开发者联盟生态市场服务商资质认证的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-15/6965f75fcb3d4b1cb57b57918dafafb2.pdf",
    date: "2024-04-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-018",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-01/5edf7b56f3974ca989ee28241ced3422.pdf",
    date: "2024-04-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-017",
    content: "[临时公告]艾融软件:关于公司与星闪联盟签署“金融行业应用领域”产业合作意向书的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-04-01/db3540fbeed14b9da817276f72f7f8a3.pdf",
    date: "2024-04-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-016",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-03-21/2286765bb34e4101b3796852e4e28c51.pdf",
    date: "2024-03-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-015",
    content: "[临时公告]艾融软件:股票解除限售公告",
    url: "https://www.bse.cn/disclosure/2024/2024-03-20/1bab50ef67664de08f54c3e7f52a4f5e.pdf",
    date: "2024-03-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-014",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-03-19/fa003a366c8c4b74a3d7ec5f6be0d890.pdf",
    date: "2024-03-19",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  }, {
    annoce_Num: "2024-013",
    content: "[临时公告]艾融软件:首次回购股份暨回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2024/2024-03-15/145d6b2937534390888c7c1d5855effa.pdf",
    date: "2024-03-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-012",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-03-07/727a2e7677024580982c1f1b1de92291.pdf",
    date: "2024-03-07",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-011",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2024/2024-03-04/810b4ee4bb304fbd93d9027beb8b5d15.pdf",
    date: "2024-03-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-010",
    content: "[临时公告]艾融软件:关于投资者关系活动记录表的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-29/1869a357d64c4e8cb4f0bd8add7b5951.pdf",
    date: "2024-02-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-009",
    content: "[临时公告]艾融软件:2023年年度业绩快报公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-28/a8346d5d6ab04bd0be9dceaa39d9c723.pdf",
    date: "2024-02-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-008",
    content: "[临时公告]艾融软件:前十大股东和前十大无限售条件股东情况公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-21/377df16ccfc748968b9eec8640319e34.pdf",
    date: "2024-02-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-007",
    content: "[临时公告]艾融软件:重大项目中标公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-07/7801a21204b64ecea5dcf4fba8d1ef46.pdf",
    date: "2024-02-07",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-006",
    content: "[临时公告]艾融软件:关于以集中竞价交易方式回购股份方案、回购报告书暨落实“提质守信重回报”行动方案的公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-07/d0ca7f743cb84a13aaf88205bfe19951.pdf",
    date: "2024-02-07",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-005",
    content: "[临时公告]艾融软件:第四届监事会第六次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-07/e8b0f13b3f604d269b024658291a063f.pdf",
    date: "2024-02-07",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-004",
    content: "[临时公告]艾融软件:第四届董事会第七次会议决议公告",
    url: "https://www.bse.cn/disclosure/2024/2024-02-07/57b434e3c0674876aec28eb2eee5e114.pdf",
    date: "2024-02-07",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-003",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-01-25/5f0dfe0d6c20471487f77344c1960bf9.pdf",
    date: "2024-01-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-002",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-01-12/2dd3fcfb214e4195953aacca248023b2.pdf",
    date: "2024-01-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2024-001",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2024/2024-01-05/83698bb48fc44cebb94b9b59bd774bed.pdf",
    date: "2024-01-05",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-103",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2023/2023-12-28/161144b44cdb467ba30c0447a7bfe549.pdf",
    date: "2023-12-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-102",
    content: "[临时公告]艾融软件:关于公司正式成为星闪联盟会员单位的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-12-27/9e8f0755b34047f8bd182337e9167c06.pdf",
    date: "2023-12-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-101",
    content: "[临时公告]艾融软件:关于公司参与项目获得国家重点研发计划“社会治理与智慧社会科技支撑”重点专项2023年度立项的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-12-25/acd31c6028c341948f998b8ae1b88029.pdf",
    date: "2023-12-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-100",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2023/2023-12-20/5e87c44ccdbb40928071489e2267b742.pdf",
    date: "2023-12-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-099",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2023/2023-12-13/e59d7cff63194c838e9e45a46842e81b.pdf",
    date: "2023-12-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-098",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-12-06/83ca5ff2aeb1415ca7b55ca6c4f85277.pdf",
    date: "2023-12-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-097",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-12-04/64eeaec8c883472f9f4f853e813b5659.pdf",
    date: "2023-12-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-096",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-11-29/1be62fe2b7c240599f63f4563c979051.pdf",
    date: "2023-11-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2023年第三次临时股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2023/2023-11-15/2f81c68771a94d47a347e95a19ceaadf.pdf",
    date: "2023-11-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-095",
    content: "[临时公告]艾融软件:2023年第三次临时股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-11-15/09e7d6d1c0e244829342a8d852250c05.pdf",
    date: "2023-11-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-094",
    content: "[临时公告]艾融软件:关于召开2023年第三次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/8a520c686a2941e6b3c03886258ab64a.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-093",
    content: "[临时公告]艾融软件:董事会薪酬与考核委员会工作细则",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/c52ffba40c14420c8aa48b93404573f5.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-092",
    content: "[临时公告]艾融软件:董事会提名委员会工作细则",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/12237de38ddd4c96a4c7786a0ccb84eb.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-091",
    content: "[临时公告]艾融软件:董事会审计委员会工作细则",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/bfa13c9d981a47a28d073938e346abf0.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-090",
    content: "[临时公告]艾融软件:内部控制制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/6b6e79823a7a4a23978166d2a89d344c.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-089",
    content: "[临时公告]艾融软件:内部审计制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/793649251f2b452f919d94d8bac8f869.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-088",
    content: "[临时公告]艾融软件:承诺管理制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/d759638a35cd4363a23c81ceea7dba61.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-087",
    content: "[临时公告]艾融软件:利润分配管理制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/e07bc48b131f49dab827f9194ec256c1.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-086",
    content: "[临时公告]艾融软件:募集资金管理制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/1cbfe5031d7c4b7ab3ff3e2f1f4ba612.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-085",
    content: "[临时公告]艾融软件:关联交易管理制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/882478b64f364cd986d6ab3f71384ba1.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-084",
    content: "[临时公告]艾融软件:对外担保管理制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/bee3c6cb6a254425b88d17ddffbde048.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-083",
    content: "[临时公告]艾融软件:独立董事工作制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/75633bdf4063447b9e28dd7a92ecb9aa.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-082",
    content: "[临时公告]艾融软件:董事会制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/4823ce80aa5543958e2ebe34b83cc583.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-081",
    content: "[临时公告]艾融软件:股东大会制度",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/3e60ff29e53d4d9d9d54c6bba5fb4782.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-080",
    content: "[临时公告]艾融软件:公司章程",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/a5c6cccf6ab941e3908961f7979813b0.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-079",
    content: "[临时公告]艾融软件:关于拟修订《公司章程》公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/bef04e9503e74da78dadc27b1ede023d.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-078",
    content: "[临时公告]艾融软件:关于改选第四届董事会审计委员会委员的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/10f65772022a4f1c9e0feac749368529.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-077",
    content: "[临时公告]艾融软件:第四届监事会第五次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/5206d6ac73d446ab8fd6b3c516af0a7b.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-076",
    content: "[临时公告]艾融软件:独立董事关于第四届董事会第六次会议相关事项的独立意见",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/53b5eb7076da4ccf90bdff4acee04e78.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-075",
    content: "[临时公告]艾融软件:第四届董事会第六次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/0c01bc8c985a40d891b00253bc83a7a3.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-074",
    content: "[定期报告]艾融软件:2023年第三季度报告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-27/3be4d22f0f2d4c6cb9dffcef50101891.pdf",
    date: "2023-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-073",
    content: "[临时公告]艾融软件:关于完成工商变更登记及公司章程备案并取得营业执照的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-11/2fe9a77e6a85426299a730fdbd140254.pdf",
    date: "2023-10-11",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-072",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-10-09/d620ca00b8d44ce785a4dee51db40a2d.pdf",
    date: "2023-10-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-071",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2023/2023-09-08/a6536671177546d59de58e9eb5f10960.pdf",
    date: "2023-09-08",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-070",
    content: "[临时公告]艾融软件:关于参加2023年上海辖区上市公司集体接待日暨中报业绩说明会预告公告",
    url: "https://www.bse.cn/disclosure/2023/2023-09-01/09521efd03bc468787c05c16a63fee5b.pdf",
    date: "2023-09-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-069",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-31/a6f99f0a7ea94f43b07b9fe8cac5add5.pdf",
    date: "2023-08-31",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-068",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-29/6ad63ddafd3d430487c3a025fdd871b0.pdf",
    date: "2023-08-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-067",
    content: "[临时公告]艾融软件:关于变更持续督导保荐代表人的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-29/6059ce5d639b4fd090fec6432a5f2b71.pdf",
    date: "2023-08-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-066",
    content: "[临时公告]艾融软件:第四届监事会第四次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-25/652dddf0ea1843afbc7bf7f6d01fb32d.pdf",
    date: "2023-08-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-065",
    content: "[临时公告]艾融软件:第四届董事会第五次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-25/bf69d9c83a4243db8942680794c132f8.pdf",
    date: "2023-08-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-064",
    content: "[定期报告]艾融软件:2023年半年度报告摘要",
    url: "https://www.bse.cn/disclosure/2023/2023-08-25/6bb6f0a2d2e54ef6b92d8102add9a3b7.pdf",
    date: "2023-08-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-063",
    content: "[定期报告]艾融软件:2023年半年度报告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-25/7db56ed3b08f45b49ac35feb1589e3fa.pdf",
    date: "2023-08-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-062",
    content: "[临时公告]艾融软件:回购股份注销完成暨股份变动公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-23/3a7d0ace9c20440882134b1f764b20de.pdf",
    date: "2023-08-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-061",
    content: "[临时公告]艾融软件:库存股注销完成暨股份变动公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-18/c57e97d733c640bb987568b3a575e1e5.pdf",
    date: "2023-08-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2023年第二次临时股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2023/2023-08-09/3bd12e33a2ec42dfa02d28a1fc338c8d.pdf",
    date: "2023-08-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-060",
    content: "[临时公告]艾融软件:关于减少注册资本通知债权人的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-09/6c3f137ff662424b9de5d0d90904e0bc.pdf",
    date: "2023-08-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-059",
    content: "[临时公告]艾融软件:2023年第二次临时股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-09/8b09946027944063988754e65b3efaa0.pdf",
    date: "2023-08-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-058",
    content: "[临时公告]艾融软件:股权激励计划限制性股票解除限售公告",
    url: "https://www.bse.cn/disclosure/2023/2023-08-04/f35b4e07d36a4f6b921298a623a8e29a.pdf",
    date: "2023-08-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:北京观韬中茂（上海）律师事务所关于上海艾融软件股份有限公司2021年股权激励计划第二个解除限售期解除限售条件成就及回购注销部分限制性股票相关事宜的法律意见书",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/9df515f738fc47b78f9013d3d5d78dae.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-057",
    content: "[临时公告]艾融软件:关于召开2023年第二次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/fdbbe0fd343b4deba6a1822d85e796ce.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-056",
    content: "[临时公告]艾融软件:公司章程",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/416ce87097484f8b8a06e5017ee2db14.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-055",
    content: "[临时公告]艾融软件:关于拟修订《公司章程》公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/68c427827a794b8c866672ed5c6c59b6.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-054",
    content: "[临时公告]艾融软件:关于注销部分已回购股份的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/460c56deace84a0f8bad4dda5613aad2.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-053",
    content: "[临时公告]艾融软件:关于回购注销部分股权激励计划限制性股票方案的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/1e9dfe8c402b47b1b7d9ee3753df1523.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-052",
    content: "[临时公告]艾融软件:关于公司股权激励计划第二个解除限售期解限售条件成就的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/d14b464fcade4a399e89dd3aaa11929b.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-051",
    content: "[临时公告]艾融软件:监事会关于第四届监事会第三次会议相关事项的审核意见",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/52c302dafbee48f8ab249bcdcde698fa.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-050",
    content: "[临时公告]艾融软件:第四届监事会第三次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/5dfc80f225374650a4ac9c7beefcb051.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-049",
    content: "[临时公告]艾融软件:独立董事关于第四届董事会第四次会议相关事项的独立意见",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/f6ec5a3e29674f5a84b2f18abdecf3e5.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-048",
    content: "[临时公告]艾融软件:第四届董事会第四次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-21/ef59b4dede4545bd86525ba2774c0d4b.pdf",
    date: "2023-07-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-047",
    content: "[临时公告]艾融软件:关于控股子公司获得上海市“专精特新”中小企业证书的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-07-14/fc591bb2079b4d57a9be9fb7fe791b6f.pdf",
    date: "2023-07-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-046",
    content: "[临时公告]艾融软件:2022 年年度权益分派实施公告",
    url: "https://www.bse.cn/disclosure/2023/2023-05-26/521d99b5ceac4dd1aa1859fdb1cb8637.pdf",
    date: "2023-05-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2022年年度股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2023/2023-05-22/946ab0ab8d5046958c6851a4e086d627.pdf",
    date: "2023-05-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-045",
    content: "[临时公告]艾融软件:2022 年年度股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-05-22/3b3d88ab26114b25b43b35fa4e2db7c2.pdf",
    date: "2023-05-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-044",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "https://www.bse.cn/disclosure/2023/2023-05-18/b440b457fc3f4f9e85d67b60105560bc.pdf",
    date: "2023-05-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-043",
    content: "[临时公告]艾融软件:2022 年年度报告业绩说明会预告公告",
    url: "https://www.bse.cn/disclosure/2023/2023-05-11/7a6e2e159b6644948c8b1489eeb722c6.pdf",
    date: "2023-05-11",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-042",
    content: "[临时公告]艾融软件:关于设立分公司的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-05-04/f91b960778894c7bb261f19e71dcb576.pdf",
    date: "2023-05-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-041",
    content: "[临时公告]艾融软件:第四届董事会第三次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-05-04/5159d379f76d43d4852f6d849bf2c1a9.pdf",
    date: "2023-05-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-040",
    content: "[临时公告]艾融软件:重大项目中标公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-28/c3e418e90a6a445a9a6424882e0d9882.pdf",
    date: "2023-04-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:关于上海艾融软件股份有限公司非经营性资金占用及其他关联资金往来情况",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/44b1ff04cc3a437e8eec0c1a036ac4fc.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:上海艾融软件股份有限公司募集资金年度存放与使用情况鉴证报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/62fa6529fcb442bea1068b4c9244ac04.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司2022年度募集资金存放与使用情况的专项核查报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/eda2f74087c540a883e20aa0c783ac4f.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司治理专项自查及规范活动相关情况的核查报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/7d88aecd42c64e9f88ac3a9d8e06e3a3.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:2022年度审计报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/4cc68a3fb0364ca4858885a48fb1a775.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-039",
    content: "[临时公告]艾融软件:2022年年度股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/2af21d370dee4572931568e1f13635ba.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-038",
    content: "[临时公告]艾融软件:2022年度募集资金存放与使用情况的专项报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/5aca4dcb0e7c40be9c9e001fdb045cb4.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-037",
    content: "[临时公告]艾融软件:2022年年度权益分派预案公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/04149eab72394a9886c93b7db7a54ae1.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-036",
    content: "[临时公告]艾融软件:拟续聘会计师事务所公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/f26f2e868fb8487bbf5e7d63a210100b.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-035",
    content: "[临时公告]艾融软件:关于治理专项自查及规范活动相关情况的报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/d5cc31f1c7f749488de73f9663f342b9.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-034",
    content: "[临时公告]艾融软件:2022年内部控制评价报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/94be75f6990b4213bb2d09d99022d40a.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-033",
    content: "[临时公告]艾融软件:2022年度独立董事述职报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/9f2e7671523c44d290d412e7c78caeaf.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-032",
    content: "[临时公告]艾融软件:第四届监事会第二次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/3f6c023fd18f47e89840e85fd2ecfcba.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-031",
    content: "[临时公告]艾融软件:独立董事关于第四届董事会第二次会议相关事项发表的事前认可意见及独立意见",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/39713ae456084f5fa5221a22fc9b412a.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-030",
    content: "[临时公告]艾融软件:第四届董事会第二次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/44f25cad437c4521a45c03bdd7a4dbb6.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-029",
    content: "[定期公告]艾融软件:2023年第一季度报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/0e87caffc8fc417e93b603b979538a3e.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-028",
    content: "[定期公告]艾融软件:2022年年度报告摘要",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/b7d3e6ab000943a5b0a026d476c82141.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-027",
    content: "[定期公告]艾融软件:2022年年度报告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-26/4977c9e9351545fc9547c4def1c3cf9b.pdf",
    date: "2023-04-26",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-026",
    content: "[临时公告]艾融软件:回购股份结果公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-20/cfe7fd73794e452f8f48efd070abe256.pdf",
    date: "2023-04-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-025",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-12/9f94eb2aeffc40bc83a07040a2e52fbc.pdf",
    date: "2023-04-12",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-024",
    content: "[临时公告]艾融软件:关于变更持续督导保荐代表人的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-10/5f40a40fc8f940239e37e6f6f4a86277.pdf",
    date: "2023-04-10",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-023",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2023/2023-04-03/f08500420aab45a3815b1844216dfa50.pdf",
    date: "2023-04-03",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-022",
    content: "[临时公告]艾融软件:关于通过国际软件测试成熟度模型集成（TMMi）3级认证的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-03-28/751f5e39a88846419e194ed92d6f647e.pdf",
    date: "2023-03-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-021",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2023/2023-03-01/b53b2eebab6e48fbb95a5acc1c636fba.pdf",
    date: "2023-03-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-020",
    content: "[临时公告]艾融软件:2022年年度业绩快报公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-27/6215e6844c9747af9c3e20b26c572940.pdf",
    date: "2023-02-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content: "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2023年第一次临时股东大会之法律意见书",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678840_799980.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-019",
    content: "[临时公告]艾融软件:董事长、监事会主席、高级管理人员、职工代表监事换届公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678780_235348.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-018",
    content: "[临时公告]艾融软件:第四届监事会第一次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678760_475423.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-017",
    content: "[临时公告]艾融软件:独立董事关于第四届董事会第一次会议相关事项的独立意见",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678691_858900.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-016",
    content: "[临时公告]艾融软件:第四届董事会第一次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678639_564620.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-015",
    content: "[临时公告]艾融软件:2023年第一次临时股东大会决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678574_456168.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-014",
    content: "[临时公告]艾融软件:2023年第一次职工代表大会决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-06/1675678490_163059.pdf",
    date: "2023-02-06",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-013",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2023/2023-02-01/1675245013_168478.pdf",
    date: "2023-02-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-012",
    content: "[临时公告]艾融软件:关于召开2023年第一次临时股东大会通知公告（提供网络投票）",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027271_864365.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-011",
    content: "[临时公告]艾融软件:关于预计2023年度向银行申请综合授信额度的公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027266_003812.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-010",
    content: "[临时公告]艾融软件:独立董事提名人声明",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027263_258118.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-009",
    content: "[临时公告]艾融软件:独立董事候选人声明（朱以林）",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027261_043207.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-008",
    content: "[临时公告]艾融软件:独立董事候选人声明（谭毓安）",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027260_578479.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-007",
    content: "[临时公告]艾融软件:独立董事候选人声明（周宁）",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027257_307197.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-006",
    content: "[临时公告]艾融软件:非职工代表监事换届公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027256_734323.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-005",
    content: "[临时公告]艾融软件:董事换届公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027253_888135.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-004",
    content: "[临时公告]艾融软件:第三届监事会第二十六次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027238_745631.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-003",
    content: "[临时公告]艾融软件:独立董事关于第三届董事会第三十二次会议相关事项的独立意见",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027233_596624.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-002",
    content: "[临时公告]艾融软件:第三届董事会第三十二次会议决议公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-18/1674027232_865189.pdf",
    date: "2023-01-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2023-001",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2023/2023-01-03/1672738786_802082.pdf",
    date: "2023-01-03",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-103",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2022/2022-12-01/1669893350_565815.pdf",
    date: "2022-12-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-102",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "https://www.bse.cn/disclosure/2022/2022-12-01/1669893292_784564.pdf",
    date: "2022-12-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-101",
    content: "[临时公告]艾融软件:中标公告",
    url: "https://www.bse.cn/disclosure/2022/2022-11-21/1669033241_993370.pdf",
    date: "2022-11-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-100",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "https://www.bse.cn/disclosure/2022/2022-11-18/1668772294_802502.pdf",
    date: "2022-11-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2022年第二次临时股东大会之法律意见书 ",
    url: "http://www.bse.cn/disclosure/2022/2022-11-14/1668428955_720687.pdf",
    date: "2022-11-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-099",
    content: "[临时公告]艾融软件:2022年第二次临时股东大会决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-11-14/1668428865_932111.pdf",
    date: "2022-11-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-098",
    content: "[临时公告]艾融软件:关于募集资金使用完毕及注销募集资金专户的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-11-14/1668421760_225455.pdf",
    date: "2022-11-14",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-097",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-11-01/1667300179_990369.pdf",
    date: "2022-11-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-096",
    content:
      "[临时公告]艾融软件:关于召开2022年第二次临时股东大会通知公告（提供网络投票）",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666867007_821054.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-095",
    content: "[临时公告]艾融软件:内幕信息知情人登记管理制度",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666866829_926327.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-094",
    content: "[临时公告]艾融软件:投资者关系管理制度",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666866759_640105.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-093",
    content: "[临时公告]艾融软件:对外投资管理制度",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666866654_087636.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-092",
    content: "[临时公告]艾融软件:第三届监事会第二十五次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666866500_167976.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-091",
    content: "[临时公告]艾融软件:第三届董事会第三十一次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666866413_441689.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-090",
    content: "[定期报告]艾融软件:2022年第三季度报告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-27/1666868698_208663.pdf",
    date: "2022-10-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-089",
    content:
      "[临时公告]艾融软件:关于收到发明专利证书及计算机软件著作权登记证书的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-11/1665479846_671279.pdf",
    date: "2022-10-11",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-088",
    content: "[临时公告]艾融软件:中标公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-10/1665400924_767858.pdf",
    date: "2022-10-10",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-087",
    content: "[临时公告]艾融软件:关于完成工商变更登记并取得营业执照的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-10/1665400882_982855.pdf",
    date: "2022-10-10",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-086",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-10-10/1665400844_958895.pdf",
    date: "2022-10-10",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-085",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-09-01/1662029282_524854.pdf",
    date: "2022-09-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-084",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-09-01/1662029234_247987.pdf",
    date: "2022-09-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-083",
    content:
      "[临时公告]艾融软件:2022 年半年度募集资金存放与实际使用情况的专项报告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857667_701988.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-082",
    content: "[临时公告]艾融软件:第三届监事会第二十四次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857571_425837.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-081",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第三十次会议相关事项的独立意见",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857509_592406.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-080",
    content: "[临时公告]艾融软件:第三届董事会第三十次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857386_605738.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-079",
    content: "[定期公告]艾融软件:半年度报告摘要",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857325_254175.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-078",
    content: "[定期公告]艾融软件:半年度报告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-30/1661857326_585816.pdf",
    date: "2022-08-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-077",
    content: "[临时公告]艾融软件:回购股份注销完成暨股份变动公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-18/1660820871_516764.pdf",
    date: "2022-08-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-076",
    content: "[临时公告]艾融软件:股权激励计划限制性股票解除限售公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-09/1660044301_131806.pdf",
    date: "2022-08-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2022年第一次临时股东大会之法律意见书",
    url: "http://www.bse.cn/disclosure/2022/2022-08-05/1659702224_630931.pdf",
    date: "2022-08-05",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-075",
    content: "[临时公告]艾融软件:关于减少注册资本通知债权人的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-05/1659702174_211068.pdf",
    date: "2022-08-05",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-074",
    content: "[临时公告]艾融软件:2022 年第一次临时股东大会决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-05/1659702043_420469.pdf",
    date: "2022-08-05",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-073",
    content: "[临时公告]艾融软件:关于完成工商变更登记并取得营业执照的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-01/1659348005_900040.pdf",
    date: "2022-08-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-072",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-08-01/1659347946_501279.pdf",
    date: "2022-08-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021 年股权激励计划第一个解除限售期解除限售条件成就及回购注销部分限制性股票相关事宜的法律意见书",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658326735_461074.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "",
    content:
      "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司使用闲置募集资金进行现金管理的核查意见",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658326497_876090.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-071",
    content:
      "[临时公告]艾融软件:关于召开 2022 年第一次临时股东大会通知公告（提供网络投票）",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658326378_263995.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-070",
    content: "[临时公告]艾融软件:使用闲置募集资金进行现金管理的公告 ",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658326316_159475.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-069",
    content: "[临时公告]艾融软件:上海艾融软件股份有限公司章程 ",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325542_972032.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-068",
    content: "[临时公告]艾融软件:关于减少公司注册资本暨修订《公司章程》公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325493_079070.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-067",
    content:
      "[临时公告]艾融软件:关于回购注销部分股权激励计划限制性股票方案的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325409_167428.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-066",
    content:
      "[临时公告]艾融软件:关于公司股权激励计划第一个解除限售期解限售条件成就的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325276_158912.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-065",
    content:
      "[临时公告]艾融软件:监事会关于公司股权激励计划第一个解限售条件成就及部分限制性股票回购注销相关事项的审核意见",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325209_571715.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-064",
    content: "[临时公告]艾融软件:第三届监事会第二十三次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325140_949498.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-063",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十九次会议相关事项的独立意见",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325086_995955.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-062",
    content: "[临时公告]艾融软件:第三届董事会第二十九次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-20/1658325018_849030.pdf",
    date: "2022-07-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-061",
    content: "[临时公告]艾融软件:股东提前终止减持计划暨减持股份结果公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-13/1657710414_446489.pdf",
    date: "2022-07-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-060",
    content: "[临时公告]艾融软件:股东减持股份进展公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-13/1657710324_027736.pdf",
    date: "2022-07-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-059",
    content:
      "[临时公告]艾融软件:持股 5%以上股东提前终止减持计划暨减持股份结果公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-04/1656936884_312957.pdf",
    date: "2022-07-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-058",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-07-01/1656677822_779329.pdf",
    date: "2022-07-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-057",
    content: "[临时公告]艾融软件:关于利用闲置自有资金购买理财产品的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-30/1656588118_250601.pdf",
    date: "2022-06-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-056",
    content: "[临时公告]艾融软件:第三届监事会第二十二次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-30/1656587975_581230.pdf",
    date: "2022-06-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-055",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十八次会议相关事项的独立意见",
    url: "http://www.bse.cn/disclosure/2022/2022-06-30/1656587918_938092.pdf",
    date: "2022-06-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-054",
    content: "[临时公告]艾融软件:第三届董事会第二十八次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-30/1656587827_929605.pdf",
    date: "2022-06-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-053",
    content: "[临时公告]艾融软件:持股 5%以上股东持股变动的公告 ",
    url: "http://www.bse.cn/disclosure/2022/2022-06-30/1656587748_086158.pdf",
    date: "2022-06-30",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-052",
    content: "[临时公告]艾融软件:关于向银行申请授信暨全资子公司股权质押的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-24/1656066977_059784.pdf",
    date: "2022-06-24",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-051",
    content: "[临时公告]艾融软件:第三届董事会第二十七次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-24/1656066896_769289.pdf",
    date: "2022-06-24",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-050",
    content: "[临时公告]艾融软件:持股 5%以上股东持股变动的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-23/1655984231_563069.pdf",
    date: "2022-06-23",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-049",
    content: "[临时公告]艾融软件:股东减持股份计划公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-17/1655463387_183874.pdf",
    date: "2022-06-17",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-048",
    content: "[临时公告]艾融软件:持股 5%以上股东持股变动的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-15/1655290625_621944.pdf",
    date: "2022-06-15",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-047",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-06-01/1654084380_903069.pdf",
    date: "2022-06-01",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-046",
    content: "[临时公告]艾融软件:2021 年年度权益分派实施公告（更正后）",
    url: "http://www.bse.cn/disclosure/2022/2022-05-27/1653644617_300217.pdf",
    date: "2022-05-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-045",
    content: "[临时公告]艾融软件:2021 年年度权益分派实施公告（更正公告）",
    url: "http://www.bse.cn/disclosure/2022/2022-05-27/1653644616_060793.pdf",
    date: "2022-05-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-044",
    content: "[临时公告]艾融软件:公司章程",
    url: "http://www.bse.cn/disclosure/2022/2022-05-25/1653472485_879368.pdf",
    date: "2022-05-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-043",
    content: "[临时公告]艾融软件:关于回购股份价格调整的提示性公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-25/1653472393_942506.pdf",
    date: "2022-05-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-042",
    content: "[临时公告]艾融软件:关于持股 5%以上股东减持价格调整的提示性公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-25/1653472331_690683.pdf",
    date: "2022-05-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-041",
    content: "[临时公告]艾融软件:2021 年年度权益分派实施公告（已取消）",
    url: "http://www.bse.cn/disclosure/2022/2022-05-25/1653471883_010823.pdf",
    date: "2022-05-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年年度股东大会之法律意见书",
    url: "http://www.bse.cn/disclosure/2022/2022-05-20/1653036046_607886.pdf",
    date: "2022-05-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-040",
    content: "[临时公告]艾融软件:2021 年年度股东大会决议公告 ",
    url: "http://www.bse.cn/disclosure/2022/2022-05-20/1653035934_200952.pdf",
    date: "2022-05-20",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-039",
    content: "[临时公告]艾融软件:投资者关系活动记录表",
    url: "http://www.bse.cn/disclosure/2022/2022-05-18/1652871148_877143.pdf",
    date: "2022-05-18",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-038",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-13/1652439675_707533.pdf",
    date: "2022-05-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-037",
    content: "[临时公告]艾融软件:2021 年年度报告说明会预告公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-13/1652439597_620799.pdf",
    date: "2022-05-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-036",
    content:
      "[临时公告]艾融软件:关于疫情防控期间参加 2021 年年度股东大会相关注意事项的提示性公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-13/1652439409_733179.pdf",
    date: "2022-05-13",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-035",
    content: "[临时公告]艾融软件:首次回购股份暨回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-11/1652263609_654149.pdf",
    date: "2022-05-11",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-034",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-09/1652087657_624480.pdf",
    date: "2022-05-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-033",
    content: "[临时公告]艾融软件:关于补发回购进展情况公告的声明公告",
    url: "http://www.bse.cn/disclosure/2022/2022-05-09/1652087658_220968.pdf",
    date: "2022-05-09",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-032",
    content: "[临时公告]艾融软件:第三届监事会第二十一次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-29/1651223000_043702.pdf",
    date: "2022-04-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-031",
    content: "[临时公告]艾融软件:第三届董事会第二十六次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-29/1651222938_673157.pdf",
    date: "2022-04-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-030",
    content: "[定期报告]艾融软件:2022年第一季度报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-29/1651222913_422539.pdf",
    date: "2022-04-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-029",
    content: "[临时公告]艾融软件:前十大股东和前十大无限售条件股东情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-25/1650884243_744325.pdf",
    date: "2022-04-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[临时公告]艾融软件:关于上海艾融软件股份有限公司非经营性资金占用及其他关联资金往来情况的专项审计说明",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635355_570167.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[临时公告]艾融软件:上海艾融软件股份有限公司募集资金年度存放与使用情况鉴证报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635304_738016.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司2021年度募集资金存放与使用情况的专项核查报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635251_798015.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[券商公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司对外担保的核查意见",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635183_446920.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    content:
      "[券商公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司治理专项自查及规范活动相关情况的核查报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635118_365268.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-028",
    content: "[临时公告]艾融软件:关于披露2021年年度报告的提示性公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632735_524880.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-027",
    content:
      "[临时公告]艾融软件:关于召开2021年年度股东大会通知公告（提供网络投票）",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632484_939493.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-026",
    content: "[临时公告]艾融软件:关于预计2022年度向银行申请综合授信额度的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632399_110159.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-025",
    content:
      "[临时公告]艾融软件:为子公司向银行等金融机构申请综合授信额度提供担保的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632308_914608.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-024",
    content: "[临时公告]艾融软件:回购股份方案公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632073_100502.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-023",
    content: "[临时公告]艾融软件:会计政策变更公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632018_987617.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-022",
    content: "[临时公告]艾融软件:2021年度募集资金存放与实际使用情况的专项报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650631920_241540.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-021",
    content: "[临时公告]艾融软件:关于拟修订公司章程公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630662_513536.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-020",
    content: "[临时公告]艾融软件:2021年年度权益分派预案公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630586_971780.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-019",
    content: "[临时公告]艾融软件:拟续聘会计师事务所公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630530_988239.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-018",
    content: "[临时公告]艾融软件:关于治理专项自查及规范活动相关情况的报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630471_130834.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-017",
    content: "[临时公告]艾融软件:内部控制评价报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630398_506404.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-016",
    content: "[临时公告]艾融软件:2021年度独立董事述职报告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630293_934479.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-015",
    content: "[临时公告]艾融软件:第三届监事会第二十次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630225_787369.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-014",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十五次会议相关事项的独立意见",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630168_681304.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-013",
    content: "[临时公告]艾融软件:第三届董事会第二十五次会议决议公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630111_371801.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-012",
    content: "[定期报告]艾融软件:2021年年度报告摘要",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650629914_848245.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-011",
    content: "[定期报告]艾融软件:2021年年度报告摘要",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650629913_953552.pdf",
    date: "2022-04-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-010",
    content: "[临时公告]艾融软件:持股5%以上股东减持股份计划公告",
    url: "http://www.bse.cn/disclosure/2022/2022-04-11/1649673340_596331.pdf",
    date: "2022-04-11",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-009",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-03-29/1648548926_615607.pdf",
    date: "2022-03-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-008",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-03-29/1648548868_645306.pdf",
    date: "2022-03-29",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-007",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-03-28/1648465865_273982.pdf",
    date: "2022-03-28",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-006",
    content:
      "[临时公告]艾融软件:关于入选国家首批特色化示范性软件学院合作企业的公告",
    url: "http://www.bse.cn/disclosure/2022/2022-03-22/1647939199_653500.pdf",
    date: "2022-03-22",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-005",
    content: "[临时公告]艾融软件:持股5%以上股东减持股份计划公告",
    url: "http://www.bse.cn/disclosure/2022/2022-03-17/1647513713_625158.pdf",
    date: "2022-03-17",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-004",
    content: "[临时公告]艾融软件:2021年年度业绩快报公告",
    url: "http://www.bse.cn/disclosure/2022/2022-02-25/1645784491_765706.pdf",
    date: "2022-02-25",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-003",
    content: "[临时公告]艾融软件:回购股份结果公告",
    url: "http://www.bse.cn/disclosure/2022/2022-01-27/1643269058_718596.pdf",
    date: "2022-01-27",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-002",
    content: "[临时公告]艾融软件:股票解除限售公告",
    url: "http://www.bse.cn/disclosure/2022/2022-01-21/1642759478_600736.pdf",
    date: "2022-01-21",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    annoce_Num: "2022-001",
    content: "[临时公告]艾融软件:回购进展情况公告",
    url: "http://www.bse.cn/disclosure/2022/2022-01-04/1641290931_536761.pdf",
    date: "2022-01-04",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-25",
    url: "http://www.bse.cn/disclosure/2022/2022-04-25/1650884243_744325.pdf",
    content: "[临时公告]艾融软件:前十大股东和前十大无限售条件股东情况公告",
    annoce_Num: "2022-029",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635355_570167.pdf",
    content:
      "[临时公告]艾融软件:关于上海艾融软件股份有限公司非经营性资金占用及其他关联资金往来情况的专项审计说明",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635304_738016.pdf",
    content:
      "[临时公告]艾融软件:上海艾融软件股份有限公司募集资金年度存放与使用情况鉴证报告",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635251_798015.pdf",
    content:
      "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司2021年度募集资金存放与使用情况的专项核查报告",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635183_446920.pdf",
    content:
      "[券商公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司对外担保的核查意见",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650635118_365268.pdf",
    content:
      "[券商公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司治理专项自查及规范活动相关情况的核查报告",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632735_524880.pdf",
    content: "[临时公告]艾融软件:关于披露2021年年度报告的提示性公告",
    annoce_Num: "2022-028",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632484_939493.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年年度股东大会通知公告（提供网络投票）	",
    annoce_Num: "2022-027",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632399_110159.pdf",
    content: "[临时公告]艾融软件:关于预计2022年度向银行申请综合授信额度的公告",
    annoce_Num: "2022-026",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632308_914608.pdf",
    content:
      "[临时公告]艾融软件:为子公司向银行等金融机构申请综合授信额度提供担保的公告",
    annoce_Num: "2022-025",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632073_100502.pdf",
    content: "[临时公告]艾融软件:回购股份方案公告",
    annoce_Num: "2022-024",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650632018_987617.pdf",
    content: "[临时公告]艾融软件:会计政策变更公告	",
    annoce_Num: "2022-023",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650631920_241540.pdf",
    content: "[临时公告]艾融软件:2021年度募集资金存放与实际使用情况的专项报告",
    annoce_Num: "2022-022",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630662_513536.pdf",
    content: "[临时公告]艾融软件:关于拟修订公司章程公告",
    annoce_Num: "2022-021",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630586_971780.pdf",
    content: "[临时公告]艾融软件:2021年年度权益分派预案公告",
    annoce_Num: "2022-020",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630530_988239.pdf",
    content: "[临时公告]艾融软件:拟续聘会计师事务所公告",
    annoce_Num: "2022-019",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630471_130834.pdf",
    content: "[临时公告]艾融软件:关于治理专项自查及规范活动相关情况的报告",
    annoce_Num: "2022-018",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630398_506404.pdf",
    content: "[临时公告]艾融软件:内部控制评价报告",
    annoce_Num: "2022-017",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630293_934479.pdf",
    content: "[临时公告]艾融软件:2021年度独立董事述职报告",
    annoce_Num: "2022-016",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630225_787369.pdf",
    content: "[临时公告]艾融软件:第三届监事会第二十次会议决议公告",
    annoce_Num: "2022-015",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630168_681304.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十五次会议相关事项的独立意见",
    annoce_Num: "2022-014",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650630111_371801.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十五次会议决议公告",
    annoce_Num: "2022-013",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650629914_848245.pdf",
    content: "[定期报告]艾融软件:2021年年度报告摘要",
    annoce_Num: "2022-012",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-22",
    url: "http://www.bse.cn/disclosure/2022/2022-04-22/1650629913_953552.pdf",
    content: "[定期报告]艾融软件:2021年年度报告",
    annoce_Num: "2022-011",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-04-11",
    url: "http://www.bse.cn/disclosure/2022/2022-04-11/1649673340_596331.pdf",
    content: "[临时公告]艾融软件:持股5%以上股东减持股份计划公告",
    annoce_Num: "2022-010",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-03-29",
    url: "http://www.bse.cn/disclosure/2022/2022-03-29/1648548926_615607.pdf",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    annoce_Num: "2022-009",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-03-29",
    url: "http://www.bse.cn/disclosure/2022/2022-03-29/1648548868_645306.pdf",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    annoce_Num: "2022-008",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-03-28",
    url: "http://www.bse.cn/disclosure/2022/2022-03-28/1648465865_273982.pdf",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    annoce_Num: "2022-007",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-03-22",
    url: "http://www.bse.cn/disclosure/2022/2022-03-22/1647939199_653500.pdf",
    content:
      "[临时公告]艾融软件:关于入选国家首批特色化示范性软件学院合作企业的公告",
    annoce_Num: "2022-006",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-03-17",
    url: "http://www.bse.cn/disclosure/2022/2022-03-17/1647513713_625158.pdf",
    content: "[临时公告]艾融软件:持股5%以上股东减持股份计划公告",
    annoce_Num: "2022-005",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-02-25",
    url: "http://www.bse.cn/disclosure/2022/2022-02-25/1645784491_765706.pdf",
    content: "[临时公告]艾融软件:2021年年度业绩快报公告",
    annoce_Num: "2022-004",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-01-27",
    url: "http://www.bse.cn/disclosure/2022/2022-01-27/1643269058_718596.pdf",
    content: "[临时公告]艾融软件:回购股份结果公告",
    annoce_Num: "2022-003",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-01-21",
    url: "http://www.bse.cn/disclosure/2022/2022-01-21/1642759478_600736.pdf",
    content: "[临时公告]艾融软件:股票解除限售公告",
    annoce_Num: "2022-002",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2022-01-04",
    url: "http://www.bse.cn/disclosure/2022/2022-01-04/1641290931_536761.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2022-001",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-29",
    url: "http://123.127.146.132/disclosure/2021/2021-12-29/1640769448_366945.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年第五次临时股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-29",
    url: "http://123.127.146.132/disclosure/2021/2021-12-29/1640769386_044958.pdf",
    content: "[临时公告]艾融软件:2021年第五次临时股东大会决议公告",
    annoce_Num: "2021-136",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-28",
    url: "http://123.127.146.132/disclosure/2021/2021-12-28/1640682441_602558.pdf",
    content: "[临时公告]艾融软件:关于收到上海银行项目中标通知的公告",
    annoce_Num: "2021-135",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-28",
    url: "http://123.127.146.132/disclosure/2021/2021-12-28/1640682387_135280.pdf",
    content: "[临时公告]艾融软件:关于工商银行项目中标的公告",
    annoce_Num: "2021-134",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-17",
    url: "http://www.bse.cn/disclosure/2021/2021-12-17/1639735297_189929.pdf",
    content:
      "[临时公告]艾融软件:关于收到上海浦发银行信用卡中心项目中标通知的公告",
    annoce_Num: "2021-133",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-17",
    url: "http://www.bse.cn/disclosure/2021/2021-12-17/1639735230_562587.pdf",
    content:
      "[临时公告]艾融软件:关于子公司完成工商变更登记并取得营业执照的公告",
    annoce_Num: "2021-132",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-14",
    url: "http://www.bse.cn/disclosure/2021/2021-12-14/1639470942_691839.pdf",
    content: "[临时公告]艾融软件:关于收到上海银行项目中标通知的公告",
    annoce_Num: "2021-131",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136744_505275.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年第五次临时股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-130",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136677_946167.pdf",
    content: "[临时公告]艾融软件:年报信息披露重大差错责任追究制度",
    annoce_Num: "2021-129",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136592_991441.pdf",
    content: "[临时公告]艾融软件:董事会薪酬与考核委员会工作细则",
    annoce_Num: "2021-128",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136466_117848.pdf",
    content: "[临时公告]艾融软件:董事会提名委员会工作细则",
    annoce_Num: "2021-127",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136311_832494.pdf",
    content: "[临时公告]艾融软件:董事会审计委员会工作细则",
    annoce_Num: "2021-126",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136228_214404.pdf",
    content: "[临时公告]艾融软件:董事会战略委员会工作细则",
    annoce_Num: "2021-125",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136119_069376.pdf",
    content:
      "[临时公告]艾融软件:董事、监事和高级管理人员所持公司股份及其变动管理制度",
    annoce_Num: "2021-124",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136059_286809.pdf",
    content: "[临时公告]艾融软件:内幕信息知情人登记管理制度",
    annoce_Num: "2021-123",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639136007_875860.pdf",
    content: "[临时公告]艾融软件:重大信息内部报告制度",
    annoce_Num: "2021-122",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135942_025856.pdf",
    content: "[临时公告]艾融软件:子公司管理制度",
    annoce_Num: "2021-121",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135881_337166.pdf",
    content: "[临时公告]艾融软件:内部控制制度",
    annoce_Num: "2021-120",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135817_313885.pdf",
    content: "[临时公告]艾融软件:信息披露管理制度",
    annoce_Num: "2021-119",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135764_381339.pdf",
    content: "[临时公告]艾融软件:财务总监工作细则",
    annoce_Num: "2021-118",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135681_945162.pdf",
    content: "[临时公告]艾融软件:董事会秘书工作细则",
    annoce_Num: "2021-117",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135631_968059.pdf",
    content: "[临时公告]艾融软件:总经理工作细则",
    annoce_Num: "2021-116",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135585_071355.pdf",
    content: "[临时公告]艾融软件:内部审计制度",
    annoce_Num: "2021-115",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135525_775719.pdf",
    content: "[临时公告]艾融软件:网络投票实施细则",
    annoce_Num: "2021-114",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135449_038229.pdf",
    content: "[临时公告]艾融软件:承诺管理制度",
    annoce_Num: "2021-113",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135184_034862.pdf",
    content: "[临时公告]艾融软件:利润分配管理制度",
    annoce_Num: "2021-112",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135138_871623.pdf",
    content: "[临时公告]艾融软件:累积投票制度实施细则",
    annoce_Num: "2021-111",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135076_377261.pdf",
    content:
      "[临时公告]艾融软件:防范控股股东、实际控制人及其他关联方资金占用制度",
    annoce_Num: "2021-110",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639135021_395535.pdf",
    content: "[临时公告]艾融软件:募集资金管理制度",
    annoce_Num: "2021-109",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134969_165207.pdf",
    content: "[临时公告]艾融软件:投资者关系管理制度",
    annoce_Num: "2021-108",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134917_083747.pdf",
    content: "[临时公告]艾融软件:关联交易管理制度",
    annoce_Num: "2021-107",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134874_317030.pdf",
    content: "[临时公告]艾融软件:对外担保管理制度",
    annoce_Num: "2021-106",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134814_824467.pdf",
    content: "[临时公告]艾融软件:对外投资管理制度",
    annoce_Num: "2021-105",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134770_492969.pdf",
    content: "[临时公告]艾融软件:独立董事工作制度",
    annoce_Num: "2021-104",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134699_304840.pdf",
    content: "[临时公告]艾融软件:监事会议事规则",
    annoce_Num: "2021-103",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134640_222480.pdf",
    content: "[临时公告]艾融软件:董事会议事规则",
    annoce_Num: "2021-102",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134583_148694.pdf",
    content: "[临时公告]艾融软件:股东大会议事规则",
    annoce_Num: "2021-101",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134390_035950.pdf",
    content: "[临时公告]艾融软件:公司章程",
    annoce_Num: "2021-100",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134310_754238.pdf",
    content: "[临时公告]艾融软件:关于拟修订公司章程公告",
    annoce_Num: "2021-099",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134236_113621.pdf",
    content: "[临时公告]艾融软件:股东增持股份结果公告",
    annoce_Num: "2021-098",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134169_629330.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十九次会议决议公告",
    annoce_Num: "2021-097",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134105_960140.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十四次会议相关事项的独立意见",
    annoce_Num: "2021-096",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-10",
    url: "http://www.bse.cn/disclosure/2021/2021-12-10/1639134034_632082.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十四次会议决议公告",
    annoce_Num: "2021-095",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-08",
    url: "http://www.bse.cn/disclosure/2021/2021-12-08/1638953793_578840.pdf",
    content: "[临时公告]艾融软件:重大合同公告",
    annoce_Num: "2021-094",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-12-01",
    url: "http://www.bse.cn/disclosure/2021/2021-12-01/1638353842_567410.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-093",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-26",
    url: "http://www.bse.cn/disclosure/2021/2021-11-26/1637925533_188422.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十三次会议相关事项的独立意见",
    annoce_Num: "2021-092",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-26",
    url: "http://www.bse.cn/disclosure/2021/2021-11-26/1637925486_445892.pdf",
    content: "[临时公告]艾融软件:购买资产的公告",
    annoce_Num: "2021-091",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-26",
    url: "http://www.bse.cn/disclosure/2021/2021-11-26/1637925428_918688.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十三次会议决议公告",
    annoce_Num: "2021-090",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-19",
    url: "http://www.bse.cn/disclosure/2021/2021-11-19/1637313726_701676.pdf",
    content: "[临时公告]艾融软件:关于接待机构投资者调研情况的公告",
    annoce_Num: "2021-089",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-19",
    url: "http://www.bse.cn/disclosure/2021/2021-11-16/1637048028_924213.pdf",
    content: "[临时公告]艾融软件:关于控股子公司完成增资及工商变更登记的公告",
    annoce_Num: "2021-088",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-12",
    url: "http://www.bse.cn/disclosure/2021/2021-11-12/1636707369_133139.pdf",
    content: "[临时公告]艾融软件:关于控股子公司完成增资及工商变更登记的公告",
    annoce_Num: "2021-087",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-11-01",
    url: "http://www.bse.cn/disclosure/2021/2021-11-01/1635762365_624227.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-086",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493260_227832.pdf",
    content: "[临时公告]艾融软件:重大合同公告",
    annoce_Num: "2021-085",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493258_117062.pdf",
    content: "[临时公告]艾融软件:对外投资的公告",
    annoce_Num: "2021-084	",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493252_531385.pdf",
    content: "[临时公告]艾融软件:对外投资的公告",
    annoce_Num: "2021-083",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493249_466351.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十八次会议决议公告",
    annoce_Num: "2021-082",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493241_659687.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十二次会议决议公告",
    annoce_Num: "2021-081",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-29/1635493234_928080.pdf",
    content: "[定期报告]艾融软件:2021年第三季度报告	",
    annoce_Num: "2021-080",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-15",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-15/1634294431_760590.pdf",
    content:
      "[临时公告]艾融软件:关于回购股份内幕信息知情人买卖公司股票情况的自查报告",
    annoce_Num: "2021-079",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-10-11",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-10-11/1633937803_182205.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-078",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-30/1632987829_320245.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-077",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-28/1632822589_274349.pdf",
    content:
      "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司回购股份合法合规的意见	",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-28/1632822535_041689.pdf",
    content: "[临时公告]艾融软件:回购股份方案公告",
    annoce_Num: "2021-076",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-28/1632822466_762105.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十一次会议相关事项的独立意见",
    annoce_Num: "2021-075",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-28/1632822390_793302.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十七次会议决议公告",
    annoce_Num: "2021-074",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-28/1632822337_788311.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十一次会议决议公告",
    annoce_Num: "2021-073",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-17",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-17/1631868570_037076.pdf",
    content: "[临时公告]艾融软件:2021年半年度权益分派实施公告	",
    annoce_Num: "2021-072",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-16",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-16/1631791223_632168.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年第四次临时股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-16",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-16/1631791140_832078.pdf",
    content: "[临时公告]艾融软件:2021年第四次临时股东大会决议公告",
    annoce_Num: "2021-071",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-09",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-09-09/1631186578_037480.pdf",
    content: "[临时公告]艾融软件:股权激励计划限制性股票授予结果公告",
    annoce_Num: "2021-070",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-09-03",
    url: "http://www.neeq.com.cn/uploads/1/file/public/202109/20210903125224_x4ksxwfg0w.pdf",
    content: "[临时公告]艾融软件：股东增持股份计划公告",
    annoce_Num: "2021-069",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630319393_138977.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年第四次临时股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-068",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630319345_703141.pdf",
    content: "[临时公告]艾融软件:2021年半年度权益分派预案公告	",
    annoce_Num: "2021-067",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630319287_414841.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第二十次会议相关事项的独立意见",
    annoce_Num: "2021-066",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630319228_583424.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十六次会议决议公告",
    annoce_Num: "2021-065",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630319180_003510.pdf",
    content:
      "[临时公告]艾融软件:关于2021年半年度募集资金存放与使用情况的专项报告",
    annoce_Num: "2021-064",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630318989_804423.pdf",
    content: "[定期报告]艾融软件:2021年半年度报告",
    annoce_Num: "2021-063",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-30",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-30/1630318897_800728.pdf",
    content: "[临时公告]艾融软件:第三届董事会第二十次会议决议公告",
    annoce_Num: "2021-062",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-27/1630061368_036599.pdf",
    content:
      "[临时公告]艾融软件:天健会计师事务所（特殊普通合伙）关于上海艾融软件股份有限公司重要前期差错更正情况的鉴证报告	",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-27/1630061319_199673.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第十九次会议相关事项的独立意见",
    annoce_Num: "2021-061",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-27/1630061222_405826.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十五次会议决议公告",
    annoce_Num: "2021-060",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-27/1630061096_240565.pdf",
    content: "[临时公告]艾融软件:前期会计差错更正公告",
    annoce_Num: "2021-059",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-08-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-08-27/1630060996_209160.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十九次会议决议公告",
    annoce_Num: "2021-058",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-27/1627372572_085661.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第十八次会议相关事项的独立意见",
    annoce_Num: "2021-057",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-27/1627372504_705312.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十四次会议决议公告",
    annoce_Num: "2021-056",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-27/1627372435_211917.pdf",
    content:
      "[临时公告]艾融软件:关于使用部分暂时闲置募集资金进行现金管理的公告",
    annoce_Num: "2021-055",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-27",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-27/1627372330_315699.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十八次会议决议公告",
    annoce_Num: "2021-054",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-16",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-16/1626429099_789543.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年第三次临时股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-16",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-16/1626429029_882131.pdf",
    content: "[临时公告]艾融软件:股权激励计划授予公告",
    annoce_Num: "2021-053",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-16",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-16/1626428945_765685.pdf",
    content: "[临时公告]艾融软件:2021年第三次临时股东大会决议公告",
    annoce_Num: "2021-052",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-09",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-09/1625825317_921653.pdf",
    content:
      "[临时公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司2021年股权激励计划的合法合规意见",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-09",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-09/1625825208_751068.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于公司股权激励计划（草案）相关事项的独立意见",
    annoce_Num: "2021-051",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-07-09",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-07-09/1625825130_357222.pdf",
    content:
      "[临时公告]艾融软件:监事会关于公司股权激励计划（草案） 激励对象名单的公示情况说明及核查意见",
    annoce_Num: "2021-050",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874722_061345.pdf",
    content: "[临时公告]艾融软件:股权激励计划激励对象名单",
    annoce_Num: "2021-049",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874669_315115.pdf",
    content: "[临时公告]艾融软件:关于对拟认定核心员工进行公示并征求意见的公告",
    annoce_Num: "2021-048",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874467_708513.pdf",
    content: "[临时公告]艾融软件:股权激励计划（草案）",
    annoce_Num: "2021-047",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874397_819849.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年第三次临时股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-046",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874319_375518.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十三次会议决议公告",
    annoce_Num: "2021-045",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874259_048379.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于公司第三届董事会第十七次会议相关事项的独立意见",
    annoce_Num: "2021-044",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-28/1624874163_023288.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十七次会议决议公告",
    annoce_Num: "2021-043",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-06-02",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-06-02/1622621712_567198.pdf",
    content: "[临时公告]艾融软件:关于完成工商变更登记并取得营业执照的公告",
    annoce_Num: "2021-042",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-05-17",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-05-17/1621251779_442003.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年第二次临时股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-05-17",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-05-17/1621251735_856095.pdf",
    content: "[临时公告]艾融软件:2021年第二次临时股东大会决议公告",
    annoce_Num: "2021-041",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619691297_298307.pdf",
    content: "[临时公告]艾融软件:公司章程",
    annoce_Num: "2021-040",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619691255_286698.pdf",
    content: "[临时公告]艾融软件:关于拟修订公司章程公告",
    annoce_Num: "2021-039",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619691194_602041.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年第二次临时股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-038",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619690973_878427.pdf",
    content: "[定期报告]艾融软件:2021年第一季度报告",
    annoce_Num: "2021-037",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619691124_571835.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十二次会议决议公告",
    annoce_Num: "2021-036",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-29/1619691051_959729.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十六次会议决议公告",
    annoce_Num: "2021-035",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-28/1619620086_187214.pdf",
    content: "[临时公告]艾融软件:2020年年度权益分派实施公告",
    annoce_Num: "2021-034",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-26",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-26/1619427465_346642.pdf",
    content: "[临时公告]艾融软件:回购股份结果公告",
    annoce_Num: "2021-033",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-21",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-21/1619002502_571708.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2020年年度股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-21",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-21/1619002442_792187.pdf",
    content: "[临时公告]艾融软件:2020年年度股东大会决议公告",
    annoce_Num: "2021-032",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-12",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-12/1618225103_203550.pdf",
    content: "[临时公告]艾融软件:2020年年度报告说明会预告公告",
    annoce_Num: "2021-031",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-08",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-08/1617870947_429905.pdf",
    content: "[临时公告]艾融软件:出售资产的公告",
    annoce_Num: "2021-030",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-08",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-08/1617870937_819046.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十五次会议决议公告",
    annoce_Num: "2021-029",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-04-01",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-04-01/1617269625_623827.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-028",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617191725_195175.pdf",
    content:
      "[临时公告]艾融软件:关于上海艾融软件股份有限公司非经营性资金占用及其他关联资金往来情况的专项审计说明",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617191659_238386.pdf",
    content:
      "[临时公告]艾融软件:上海艾融软件股份有限公司募集资金年度存放与使用情况鉴证报告",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617191540_688968.pdf",
    content:
      "[券商公告]艾融软件:光大证券股份有限公司关于上海艾融软件股份有限公司2020年度募集资金存放与使用情况的专项核查报告",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190884_689883.pdf",
    content: "[临时公告]艾融软件:关于提前披露2020年年度报告的提示性公告",
    annoce_Num: "2021-027",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190832_554099.pdf",
    content: "[临时公告]艾融软件:拟续聘会计师事务所公告",
    annoce_Num: "2021-026",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190727_565280.pdf",
    content: "[临时公告]艾融软件:2020年度独立董事述职报告",
    annoce_Num: "2021-025",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190628_661496.pdf",
    content: "[临时公告]艾融软件:出售资产的公告",
    annoce_Num: "2021-024",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190554_466454.pdf",
    content: "[临时公告]艾融软件:第三届监事会第十一次会议决议公告",
    annoce_Num: "2021-023",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617191384_694313.pdf",
    content: "[临时公告]艾融软件:关于2020年度募集资金存放与使用情况的专项报告",
    annoce_Num: "2021-022",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190191_037996.pdf",
    content:
      "[临时公告]艾融软件:关于召开2020年年度股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-021",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617190016_885475.pdf",
    content: "[临时公告]艾融软件:2020年年度权益分派预案公告",
    annoce_Num: "2021-020",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617189500_249812.pdf",
    content: "[定期报告]艾融软件:2020年年度报告摘要",
    annoce_Num: "2021-019",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617189499_510303.pdf",
    content: "[定期报告]艾融软件:2020年年度报告",
    annoce_Num: "2021-018",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617189859_906048.pdf",
    content:
      "[临时公告]艾融软件:独立董事关于第三届董事会第十四次会议相关事项发表的独立意见公告",
    annoce_Num: "2021-017",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-31",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-31/1617189671_754090.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十四次会议决议公告",
    annoce_Num: "2021-016",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-03-02",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-03-02/1614684911_431213.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-015",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-26",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-26/1614333226_092469.pdf",
    content:
      "[临时公告]艾融软件:上海天衍禾律师事务所关于上海艾融软件股份有限公司2021年第一次临时股东大会之法律意见书",
    annoce_Num: "",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-26",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-26/1614333152_656720.pdf",
    content: "[临时公告]艾融软件:2021年第一次临时股东大会决议公告",
    annoce_Num: "2021-014",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-26",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-26/1614333090_652367.pdf",
    content: "[临时公告]艾融软件:2020年年度业绩快报公告",
    annoce_Num: "2021-013",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-09",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-09/1612861860_426904.pdf",
    content: "[临时公告]艾融软件:股票解除限售公告",
    annoce_Num: "2021-012",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-08",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-08/1612776652_440491.pdf",
    content:
      "[临时公告]艾融软件:关于召开2021年第一次临时股东大会通知公告（提供网络投票）",
    annoce_Num: "2021-011",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-08",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-08/1612776519_475791.pdf",
    content: "[临时公告]艾融软件:第三届董事会第十三次会议决议公告",
    annoce_Num: "2021-010",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-04",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-04/1612426771_964684.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-009",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-03",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-03/1612345366_736790.pdf",
    content: "[临时公告]艾融软件:股票解除限售公告",
    annoce_Num: "2021-008",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-02-02",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-02-02/1612258016_519525.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-007",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-29",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-29/1611914503_872257.pdf",
    content: "[临时公告]艾融软件:关于延长公司股东股份锁定期的公告",
    annoce_Num: "2021-006",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-28",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-28/1611827062_738062.pdf",
    content: "[临时公告]艾融软件:回购股份实施预告公告",
    annoce_Num: "2021-005",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-21",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-21/1611223322_647654.pdf",
    content: "[临时公告]艾融软件:回购股份实施预告公告",
    annoce_Num: "2021-004",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-14",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-14/1610611275_837195.pdf",
    content: "[临时公告]艾融软件:回购股份实施预告公告",
    annoce_Num: "2021-003",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-13",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-13/1610524560_021863.pdf",
    content: "[临时公告]艾融软件:回购进展情况公告",
    annoce_Num: "2021-002",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
  {
    date: "2021-01-07",
    url: "http://www.neeq.com.cn/disclosure/2021/2021-01-07/1610011460_918368.pdf",
    content: "[临时公告]艾融软件:回购股份实施预告公告",
    annoce_Num: "2021-001",
    Securities_code: "830799",
    Securities_name: "艾融软件",
  },
];
export default {
  "get|/parameter/query": () => {
    return {
      status: 200,
      message: "success",
      data: annonceList,
    };
  },
};
