let achieventList = [{
    name: "上海艾融软件股份有限公司2019年年度报告.pdf",
    to: "http://www.bse.cn/disclosure/2020/2020-03-23/1584959986_371287.pdf",
    type: "pdf"
},{
    name: "上海艾融软件股份有限公司2019年半年报告.pdf",
    to: "http://www.bse.cn/disclosure/2019/2019-08-15/1565858444_616971.pdf",
    type: "pdf"
},{
    name: "上海艾融软件股份有限公司2019年第一季度报告.pdf",
    to: "http://www.bse.cn/disclosure/2019/2019-04-29/1556527004_842375.pdf",
    type: "pdf"
}];
export default {
    'get|/parameter/achieventList': () => {
        return {
            status: 200,
            message: 'success',
            data: achieventList
        };
    }
}
