let shareholderList = [{
    num: "01",
    name: "吴臻",
    type: "境内自然人",
    shares: "34,245,315",
    proportion: "24.3273"
}, {
    num: "02",
    name: "孟庆有",
    type: "境内自然人",
    shares: "22,274,460",
    proportion: "15.8234"
}, {
    num: "03",
    name: "张岩",
    type: "境内自然人",
    shares: "22,261,988",
    proportion: "15.8145"
}, {
    num: "04",
    name: "上海乾韫企业管理合伙企业（有限合伙）",
    type: "境内非国有法人",
    shares: "14,028,983",
    proportion: "9.9659"
}, {
    num: "05",
    name: "杨光润",
    type: "境内自然人",
    shares: "6,680,495",
    proportion: "4.7457"
}, {
    num: "06",
    name: "何继远",
    type: "境内自然人",
    shares: "3,001,050",
    proportion: "2.1319"
}, {
    num: "07",
    name: "金智伟",
    type: "境内自然人",
    shares: "2,182,549",
    proportion: "1.5504"
}, {
    num: "08",
    name: "王涛",
    type: "境内自然人",
    shares: "1,941,420",
    proportion: "1.3792"
}, {
    num: "09",
    name: "肖斌",
    type: "境内自然人",
    shares: "1,755,538",
    proportion: "1.2471"
}, {
    num: "10",
    name: "中国银行股份有限公司－富国积极成长一年定期开放混合型证券投资基金",
    type: "基金、理财产品",
    shares: "1,619,017",
    proportion: "1.1501"
},
];
export default {
    'get|/parameter/shareholderList': () => {
        return {
            status: 200,
            message: 'success',
            data: shareholderList
        };
    }
}
